import React from "react";
import Layout from "../areas/main/components/Layout";
import NavOne from "../areas/main/components/NavOne";
import Footer from "../areas/main/components/Footer";
import Texts, { currentLang, getMenuMockupImage } from "../utils/lang-utils";
import apple from "../areas/social/assets/images/downloads/apple.png";
import google from "../areas/social/assets/images/downloads/android.png";
import hand from "../areas/social/assets/images/hand.png";
import fingers from "../areas/social/assets/images/fingers.png";
import logoDark from "../areas/main/assets/images/eatella-logo.png";
import { Helmet } from "react-helmet";
import './index.scss';
import urls from "../areas/main/assets/urls";

const AppHomePage = () => (
  <Layout pageTitle="EATELLA | foodstagram, food finder, food diary, QR menu app" description="The best app for foodies who love taking pictures of food. Transform your foodstagram content into food diary and recommendations to inspire your foodie firends" language="en">
    <Helmet>
      <link
        href="/social/mobile.css"
        rel="stylesheet"
      />
    </Helmet>
    <header className="site-header site-header__header-one">
      <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <a className="navbar-brand" href="#">
              <img
                src={logoDark}
                className="main-logo"
                width="159"
                alt="alter text"
              />
            </a>
          </div>
          <div className="main-navigation">
            <ul className=" one-page-scroll-menu navigation-box">


              <li className="scrollToLink">
                <a ></a>
              </li>

              

            </ul>
          </div>
        </div>
      </nav>
    </header>

    <section className="banner-one app-intro" id="split">
      <div className="container text-center">
        <div className="row"><h2>EATELLA</h2></div>

        <div class="d-md-flex h-md-100">

          <div className="col col-md-6 p-0 h-md-100">
         <h3 className="banner-one__title ">For Foodies</h3>
          <h4>Foodstagram, food diary <br/>& food finder</h4>
            <div className="banner-one__moc">
              
               <div className="marvel-device iphone-x">
                <div className="notch">
                  <div className="camera"></div>
                  <div className="speaker"></div>
                </div>
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="bottom-bar"></div>
                <div className="volume"></div>
                <div className="overflow">
                  <div className="shadow shadow--tr"></div>
                  <div className="shadow shadow--tl"></div>
                  <div className="shadow shadow--br"></div>
                  <div className="shadow shadow--bl"></div>
                </div>
                <div className="inner-shadow"></div>
                <div className="screen">
              <iframe src="/social/app-frame/index.html" seamless frameBorder="0" scrolling="no" className="app-frame intro-mobile img-responsive"></iframe>

                </div>
              </div>

              <div className="download">
                <a href="https://itunes.apple.com/nl/app/eatellia/id1158846968" target="_blank" className="mr-1"><img src={apple} /></a>
                <a href="https://play.google.com/store/apps/details?id=com.eatellia.social" target="_blank"><img src={google} /></a>
                </div>
            </div>
          </div>

          <div className="col-md-6 p-0 h-md-100">
            <h3 className="banner-one__title">For Restaurants</h3>
            <h4><a href={urls.qrMenu}>Free QR menu</a>, <a href={urls.qrOrdering}>QR ordering</a>, <br/><a href={urls.apps}>waiter and kitchen apps</a></h4>
            <div className="banner-one__moc qr-ordering ">
                <img className="menu-moc" src={getMenuMockupImage()} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </Layout>
);

export default AppHomePage;
